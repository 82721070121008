<template>
  <div
    class="flex flex-col justify-center items-center w-full sm:max-w-md mt-6 px-6 py-4 bg-white dark:bg-gray-800 shadow-md overflow-hidden sm:rounded-lg">
    <div class="font-medium text-sm text-gray-700 dark:text-gray-400 mb-2">Managed by</div>
    <div>
      <a href="https://credecapital.co.za">
        <img
          src="/images/crede_capital_logo.png"
          class="h-20"
          alt="Crede Capital Partners" />
      </a>
    </div>
  </div>
</template>
